import React, { useState, useEffect, useCallback } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import Select from "react-select";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useDispatch } from "react-redux";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { stringIsNullOrEmpty, createMultiPartFormBody, numberWithCurrencyFormat } from "../../util/Util";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const ManageProfitLossReport = (props) => {
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();
  const ALL_OPTION_LABEL = "All";
  const ALL_OPTION_VALUE = "all";
  const MEMBER_GROUP_STRING = "Member";
  const DATE_GROUP_STRING = "Date";
  const CATEGORY_GROUP_STRING = "Category";
  const PRODUCT_GROUP_STRING = "Product";
  const [startDate, setStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(ALL_OPTION_VALUE);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(ALL_OPTION_VALUE);
  const [username, setUsername] = useState("");
  const [groupBy, setGroupBy] = useState("");
  const [profitLossDatas, setProfitLossDatas] = useState([]);
  const [triggerSearch, setTriggerSearch] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [filteredDatas, setFilteredDatas] = useState([]);
  const [firstHeader, setFirstHeader] = useState("ACCOUNT");

  /// <summary>
  /// Author : -
  /// </summary>
  const _OPTION_STYLES = {
    control: (base) => ({
      ...base,
      "min-height": "34px",
      height: "34px",
    }),
    valueContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: "34px",
      "min-height": "34px",
    }),
    input: (base) => ({
      height: "34px",
      "min-height": "34px",
    }),
  };

  let _tableColumns = [
    {
      Header: t(firstHeader),
      accessor: "account",
      Cell: ({ row }) => {
        if (row.original.memberUsername != ALL_OPTION_LABEL) {
          setFirstHeader("ACCOUNT");
          return <>{row.original.memberUsername}</>;
        } else if (
          groupBy == DATE_GROUP_STRING &&
          row.original.memberUsername == ALL_OPTION_LABEL
        ) {
          setFirstHeader("DATE");
          return (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                tableRowHandler(row.original, "Date");
              }}
            >
              {moment(row.original.dateTime).format("YYYY-MM-DD")}
            </a>
          );
        } else if (stringIsNullOrEmpty(createdBy)) {
          setFirstHeader("COMPANY");
          return (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                tableRowHandler(row.original, "Account");
              }}
            >
              {row.original.createdByFullName}
            </a>
          );
        } else {
          setFirstHeader("DATE");
          return (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                tableRowHandler(row.original, "Date");
              }}
            >
              {moment(startDate).format("YYYY-MM-DD")}
            </a>
          );
        }
      },
      Footer: () => {
        return (
          <span>
            <b>Total: </b>
          </span>
        );
      },
    },
    {
      Header: t("PRODUCT"),
      accessor: "productName",
      Cell: ({ row }) => {
        if (
          selectedProduct != ALL_OPTION_VALUE &&
          !stringIsNullOrEmpty(selectedProduct)
        ) {
          return <>{row.original.productName}</>;
        } else {
          return (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                tableRowHandler(row.original, "Product");
              }}
            >
              {row.original.productName}
            </a>
          );
        }
      },
    },
    {
      Header: t("CATEGORY"),
      accessor: "categoryName",
      Cell: ({ row }) => {
        if (
          selectedCategory != ALL_OPTION_VALUE &&
          !stringIsNullOrEmpty(selectedCategory)
        ) {
          return <>{row.original.categoryName}</>;
        } else {
          return (
            <a
              href=""
              onClick={(e) => {
                e.preventDefault();
                tableRowHandler(row.original, "Category");
              }}
            >
              {row.original.categoryName}
            </a>
          );
        }
      },
    },
    {
      Header: t("Bet Count"),
      accessor: "wager",
      minWidth: 100,
      Cell: ({ row, value }) => {
        if (row.original.wager < 0) {
          return <p style={{ color: "red" }}>{numberWithCurrencyFormat(value)}</p>
        } else {
          return <p>{value}</p>
        }
      },
      Footer: () => {
        let wagerTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.wager) {
            wagerTotal += parseFloat(filteredDatas[i].original.wager);
          }
        }
        return (
          <span>
            <b>{wagerTotal}</b>
          </span>
        );
      },
    },
    {
      Header: t("Turnover"),
      accessor: "turnover",
      Cell: ({ row }) => {
        if (row.original.turnover < 0) {
          return (
            <p style={{ color: "red" }}>{row.original.turnover.toFixed(2)}</p>
          );
        } else {
          return <p>{row.original.turnover.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.turnover) {
            amountTotal += parseFloat(filteredDatas[i].original.turnover);
          }
        }
        return (
          <span>
            <b>{amountTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("V.Turnover"),
      accessor: "validTurnover",
      Cell: ({ row }) => {
        if (row.original.validTurnover < 0) {
          return (
            <p style={{ color: "red" }}>{row.original.validTurnover.toFixed(2)}</p>
          );
        } else {
          return <p>{row.original.validTurnover.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.validTurnover) {
            amountTotal += parseFloat(filteredDatas[i].original.validTurnover);
          }
        }
        return (
          <span>
            <b>{amountTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("BET_AMOUNT"),
      accessor: "betAmount",
      Cell: ({ row }) => {
        if (row.original.betAmount < 0) {
          return (
            <p style={{ color: "red" }}>{row.original.betAmount.toFixed(2)}</p>
          );
        } else {
          return <p>{row.original.betAmount.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.betAmount) {
            amountTotal += parseFloat(filteredDatas[i].original.betAmount);
          }
        }
        return (
          <span>
            <b>{amountTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("MEMBER_PROFIT_LOSS"),
      accessor: "memberProfitLoss",
      Cell: ({ row }) => {
        if (row.original.memberProfitLoss < 0) {
          return (
            <p style={{ color: "red" }}>
              {row.original.memberProfitLoss.toFixed(2)}
            </p>
          );
        } else {
          return <p>{row.original.memberProfitLoss.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let memberPLTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.memberProfitLoss) {
            memberPLTotal += parseFloat(
              filteredDatas[i].original.memberProfitLoss
            );
          }
        }
        return (
          <span>
            <b>{memberPLTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("CLUBFEES"),
      accessor: "clubFee",
      Cell: ({ row }) => {
        return (
          <p className={row.original.clubFee < 0 ? "text-red" : ""}>
            {(row.original.clubFee).toFixed(2)}
          </p>
        );
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.clubFee) {
            amountTotal += parseFloat(filteredDatas[i].original.clubFee);
          }
        }
        return (
          <span>
            <b>{amountTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("TAX"),
      accessor: "tax",
      Cell: ({ row }) => {
        if (row.original.tax < 0) {
          return (
            <p style={{ color: "red" }}>
              {row.original.tax.toFixed(2)}
            </p>
          );
        } else {
          return <p>{row.original.tax.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let amountTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.tax) {
            amountTotal += parseFloat(filteredDatas[i].original.tax);
          }
        }
        return (
          <span>
            <b>{amountTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
    {
      Header: t("JPC"),
      accessor: "jpc",
      Cell: ({ row }) => {
        if (row.original.jpc < 0) {
          return (
            <p style={{ color: "red" }}>
              {row.original.jpc.toFixed(2)}
            </p>
          );
        } else {
          return <p>{row.original.jpc.toFixed(2)}</p>;
        }
      },
      //Footer: () => {
      //  let companyPLTotal = 0;
      //  for (let i = 0; i <= filteredDatas.length; i++) {
      //    if (filteredDatas[i] && filteredDatas[i].original.companyProfitLoss) {
      //      companyPLTotal += parseFloat(
      //        filteredDatas[i].original.companyProfitLoss
      //      );
      //    }
      //  }
      //  return (
      //    <span>
      //      <b>{companyPLTotal.toFixed(2)}</b>
      //    </span>
      //  );
      //},
    },
    {
      Header: t("JPW"),
      accessor: "jpw",
      Cell: ({ row }) => {
        if (row.original.jpw < 0) {
          return (
            <p style={{ color: "red" }}>
              {row.original.jpw.toFixed(2)}
            </p>
          );
        } else {
          return <p>{row.original.jpw.toFixed(2)}</p>;
        }
      },
      //Footer: () => {
      //  let companyPLTotal = 0;
      //  for (let i = 0; i <= filteredDatas.length; i++) {
      //    if (filteredDatas[i] && filteredDatas[i].original.companyProfitLoss) {
      //      companyPLTotal += parseFloat(
      //        filteredDatas[i].original.companyProfitLoss
      //      );
      //    }
      //  }
      //  return (
      //    <span>
      //      <b>{companyPLTotal.toFixed(2)}</b>
      //    </span>
      //  );
      //},
    },
    {
      Header: t("COMPANY_PROFIT_LOSS"),
      accessor: "companyProfitLoss",
      Cell: ({ row }) => {
        if (row.original.companyProfitLoss < 0) {
          return (
            <p style={{ color: "red" }}>
              {row.original.companyProfitLoss.toFixed(2)}
            </p>
          );
        } else {
          return <p>{row.original.companyProfitLoss.toFixed(2)}</p>;
        }
      },
      Footer: () => {
        let companyPLTotal = 0;
        for (let i = 0; i <= filteredDatas.length; i++) {
          if (filteredDatas[i] && filteredDatas[i].original.companyProfitLoss) {
            companyPLTotal += parseFloat(
              filteredDatas[i].original.companyProfitLoss
            );
          }
        }
        return (
          <span>
            <b>{companyPLTotal.toFixed(2)}</b>
          </span>
        );
      },
    },
  ];

  /// <summary>
  /// Author : -
  /// </summary>
  const tableRowHandler = (row, type) => {
    if (type == "Date") {
      if (!stringIsNullOrEmpty(row.dateTime)) {
        setStartDate(
          moment(row.dateTime).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(
          moment(row.dateTime).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
      }

      if (row.categoryName != ALL_OPTION_LABEL) {
        let categoryOption = categoryList.find(
          (category) => category.label == row.categoryName
        );
        setSelectedCategory(categoryOption.value);
      }

      if (row.productName != ALL_OPTION_LABEL) {
        let productOption = productList.find(
          (product) => product.label == row.productName
        );
        setSelectedProduct(productOption.value);
      }
      setGroupBy(MEMBER_GROUP_STRING);
    } else if (type == "Account") {
      if (row.categoryName != ALL_OPTION_LABEL) {
        let categoryOption = categoryList.find(
          (category) => category.label == row.categoryName
        );
        setSelectedCategory(categoryOption.value);
      }

      if (row.productName != ALL_OPTION_LABEL) {
        let productOption = productList.find(
          (product) => product.label == row.productName
        );
        setSelectedProduct(productOption.value);
      }
      setCreatedBy(row.createdByFullName);
      setGroupBy(DATE_GROUP_STRING);
    } else if (type == "Product") {
      if (
        !stringIsNullOrEmpty(row.dateTime) &&
        row.memberUsername == ALL_OPTION_LABEL
      ) {
        setStartDate(
          moment(row.dateTime).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(
          moment(row.dateTime).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
      }

      if (
        !stringIsNullOrEmpty(row.memberUsername) &&
        row.memberUsername != ALL_OPTION_LABEL
      ) {
        setUsername(row.memberUsername);
      }

      if (row.categoryName != ALL_OPTION_LABEL) {
        let categoryOption = categoryList.find(
          (category) => category.label == row.categoryName
        );
        setSelectedCategory(categoryOption.value);
      }

      if (row.productName == ALL_OPTION_LABEL) {
        setGroupBy(PRODUCT_GROUP_STRING);
      } else {
        let productOption = productList.find(
          (product) => product.label == row.productName
        );
        setSelectedProduct(productOption.value);
      }
    } else if (type == "Category") {
      if (
        !stringIsNullOrEmpty(row.dateTime) &&
        row.memberUsername == ALL_OPTION_LABEL
      ) {
        setStartDate(
          moment(row.dateTime).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
        setEndDate(
          moment(row.dateTime).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        );
      }

      if (
        !stringIsNullOrEmpty(row.memberUsername) &&
        row.memberUsername != ALL_OPTION_LABEL
      ) {
        setUsername(row.memberUsername);
      }

      if (row.productName != ALL_OPTION_LABEL) {
        let productOption = productList.find(
          (product) => product.label == row.productName
        );
        setSelectedProduct(productOption.value);
      }

      if (row.categoryName == ALL_OPTION_LABEL) {
        setGroupBy(CATEGORY_GROUP_STRING);
      } else {
        let categoryOption = categoryList.find(
          (category) => category.label == row.categoryName
        );
        setSelectedCategory(categoryOption.value);
      }
    }
    setTriggerSearch(!triggerSearch);
  };

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (props.id) {
      setUsername(props.username);
      searchBtnHandler(props.username);
    }
  }, [props.id]);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
    if (props.id) {
      setUsername(props.username);
      searchBtnHandler(props.username);
    }
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    if (!stringIsNullOrEmpty(username)) {
      searchBtnHandler(username);
    }
  }, [triggerSearch]);

  /// <summary>
  /// Author : -
  /// this is used to get the latest filtered record from reacttable
  /// </summary>
  const getFilteredRecords = useCallback(({ page }) => {
    setFilteredDatas(page);
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    await fetch(ApiUrl._API_GET_MASTER_PRODUCT_ASSIGNMENT_DATA, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            const accountProductOptionList = [
              { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
            ];
            responseJson[ApiKey._API_DATA_KEY].forEach(function (option) {
              accountProductOptionList.push({
                label: option.productName,
                value: option.productId,
              });
            });

            setProductList(accountProductOptionList);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });

    await fetch(ApiUrl._API_GET_PRODUCT_CATEGORY, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          const productCategorys = [
            { label: ALL_OPTION_LABEL, value: ALL_OPTION_VALUE },
          ];
          responseJson[ApiKey._API_DATA_KEY].forEach(function (
            productCategory
          ) {
            productCategorys.push({
              label: productCategory.title,
              value: productCategory.id,
            });
          });
          setCategoryList(productCategorys);
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const handleEvent = (event, picker) => {
    setStartDate(moment(picker.startDate).format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(picker.endDate).format("YYYY-MM-DD HH:mm:ss"));
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const searchBtnHandler = async (parsedUsername) => {
    let apiUrl =
      ApiUrl._API_GET_PROFIT_LOSS_REPORT +
      "?startDate=" +
      startDate +
      "&endDate=" +
      endDate;

    if (selectedProduct != ALL_OPTION_VALUE) {
      apiUrl += "&productId=" + selectedProduct;
    }

    if (selectedCategory != ALL_OPTION_VALUE) {
      apiUrl += "&categoryId=" + selectedCategory;
    }

    if (!stringIsNullOrEmpty(parsedUsername)) {
      apiUrl += "&username=" + parsedUsername;
    }

    if (!stringIsNullOrEmpty(groupBy)) {
      apiUrl += "&groupBy=" + groupBy;
    }

    if (!stringIsNullOrEmpty(createdBy)) {
      apiUrl += "&createdBy=" + createdBy;
    }

    _dispatch(setBusy());
    await fetch(apiUrl, {
      method: ApiKey._API_GET,
      headers: {
        "Content-Type": ApiKey._API_FORM_URLENCODED,
        "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        _dispatch(setIdle());
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          if (responseJson[ApiKey._API_DATA_KEY] != null) {
            setProfitLossDatas(responseJson[ApiKey._API_DATA_KEY]);
          }
          else {
            setProfitLossDatas([]);
          }
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
      });
  };

  /// <summary>
  /// Author : -
  /// </summary>
  const groupBySearchButtonHandler = (type) => {
    setSelectedCategory(ALL_OPTION_VALUE);
    setSelectedProduct(ALL_OPTION_VALUE);
    setUsername("");
    setCreatedBy("");

    setGroupBy(type);
    setTriggerSearch(!triggerSearch);
  };

  async function adminLog() {

    let params = {
      "startDate": startDate,
      "endDate": endDate,
    };
    if (selectedProduct != ALL_OPTION_VALUE) {
      params["selectedProduct"] = selectedProduct;
    }

    if (selectedCategory != ALL_OPTION_VALUE) {
      params["selectedCategory"] = selectedCategory;
    }

    if (!stringIsNullOrEmpty(groupBy)) {
      params["groupBy"] = groupBy;
    }

    if (!stringIsNullOrEmpty(createdBy)) {
      params["createdBy"] = createdBy;
    }

    _dispatch(setSaveAdminLog("VIEW_BO_PROFIT_LOSS_REPORT", _history.location.pathname, params));
  }

  return (
    <div>
      <h1 className="page-header">
        {stringIsNullOrEmpty(props.id) && t("PROFIT_LOSS_REPORT")}
      </h1>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-3">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-calendar"></i>
                    </span>
                  </div>
                  <div style={{ width: "80%" }}>
                    <DateRangePicker
                      containerStyles={{ width: "100%" }}
                      startDate={startDate}
                      endDate={endDate}
                      onApply={handleEvent}
                      alwaysShowCalendars={true}
                      locale={{ format: "YYYY-MM-DD HH:mm:ss" }}
                      timePicker={true}
                      timePickerSeconds={true}
                      ranges={{
                        Today: [moment().startOf("day"), moment().endOf("day")],
                        Yesterday: [
                          moment().subtract(1, "days").startOf("day"),
                          moment().subtract(1, "days").endOf("day"),
                        ],
                        "Last 7 Days": [
                          moment().subtract(6, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "Last 30 Days": [
                          moment().subtract(29, "days").startOf("day"),
                          moment().endOf("day"),
                        ],
                        "This Month": [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        "Last Month": [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                        "This Year": [
                          moment().startOf("year"),
                          moment().endOf("year"),
                        ],
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        value={startDate + " - " + endDate}
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-gamepad"></i>
                    </span>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Select
                      styles={_OPTION_STYLES}
                      value={productList.filter(
                        (product) => product.value == selectedProduct
                      )}
                      options={productList}
                      onChange={(e) => {
                        setSelectedProduct(e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-tag"></i>
                    </span>
                  </div>
                  <div style={{ width: "70%" }}>
                    <Select
                      styles={_OPTION_STYLES}
                      value={categoryList.filter(
                        (category) => category.value == selectedCategory
                      )}
                      options={categoryList}
                      onChange={(e) => {
                        setSelectedCategory(e.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div
                className="form-group"
                style={{ display: "flex", width: "100%" }}
              >
                <div
                  className="input-group"
                  style={{ width: "100%", flexFlow: "nowrap" }}
                >
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    value={username}
                    placeholder={t("USERNAME")}
                    readOnly={!stringIsNullOrEmpty(props.username)}
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  searchBtnHandler(username);
                  adminLog();
                }}
              >
                <i className="fas fa-search"></i> {t("SEARCH")}
              </button>
            </div>
            {stringIsNullOrEmpty(props.id) && (
              <div className="col-lg-12">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    groupBySearchButtonHandler(DATE_GROUP_STRING);
                  }}
                >
                  <i className="fas fa-search"></i> {t("DATE")}
                </button>
                <button
                  type="button"
                  style={{ margin: "10px" }}
                  onClick={() => {
                    groupBySearchButtonHandler(PRODUCT_GROUP_STRING);
                  }}
                  className="btn btn-primary"
                >
                  <i className="fas fa-search"></i> {t("PRODUCT")}
                </button>
                <button
                  type="button"
                  style={{ margin: "10px" }}
                  onClick={() => {
                    groupBySearchButtonHandler(CATEGORY_GROUP_STRING);
                  }}
                  className="btn btn-primary"
                >
                  <i className="fas fa-search"></i> {t("CATEGORY")}
                </button>
                <button
                  type="button"
                  style={{ margin: "10px" }}
                  onClick={() => {
                    groupBySearchButtonHandler(MEMBER_GROUP_STRING);
                  }}
                  className="btn btn-primary"
                >
                  <i className="fas fa-search"></i> {t("USERNAME")}
                </button>
              </div>
            )}
          </div>
          <hr />
          <ReactTable
            data={profitLossDatas}
            columns={_tableColumns}
            renderFooter={true}
            getFilteredRecords={getFilteredRecords}
          />
        </PanelBody>
      </Panel>
    </div>
  );
};

export default ManageProfitLossReport;