import React, { useState } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import moment from "moment";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import {
    ApiKey,
    ApiUrl,
    LanguageKey,
    Status,
    DEFAULT_PAGE_SIZE,
} from "../../util/Constant";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ApiEngine from "../../util/ApiEngine";
import {
    stringIsNullOrEmpty,
    numberWithCurrencyFormat,
    isObjectEmpty,
} from "../../util/Util";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";

/// <summary>
/// Author : -
/// </summary>

const MemberCreditReport = (props) => {
    const _dispatch = useDispatch();
    let _history = useHistory();
    const { t } = useTranslation(LanguageKey._PRIMARY);
    const _ALL_VALUE = -1;
    const _OVERALL_KEYS = {
        IN: "TOTAL_IN",
        OUT: "TOTAL_OUT",
    };
    const [startDate, setStartDate] = useState(
        moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
    );
    const [endDate, setEndDate] = useState(
        moment().format("YYYY-MM-DD HH:mm:ss")
    );
    const [apiUrl, setApiUrl] = useState("");
    const [memberOption, setMemberOption] = useState([]);
    const [memberId, setMemberId] = useState("");
    const [status, setStatus] = useState(_ALL_VALUE);
    const [initFinish, setInitFinish] = useState(false);
    const [overallInfo, setOverallInfo] = useState({});
    const [nodePathMembers, setNodePathMembers] = useState([]);

    const [selectedMember, setSelectedMember] = useState();

    /// <summary>
    /// Author : -
    /// </summary>
    useEffect(() => {
        getMemberCreditReport();
    }, [initFinish]);

    /// <summary>
    /// Author : -
    /// </summary>
    async function getMemberCreditReport() {
        let fetchUrl =
            ApiUrl._API_GET_MEMBER_CREDIT_REPORT 

        setApiUrl(fetchUrl);
    }

    let _tableColumns = [
        {
            Header: "TARGET_MEMBER",
            accessor: "memberName",
            Cell: ({ row }) => {
                return (
                    <span
                        className={classNames({
                            "clickable-username":
                                nodePathMembers.length > 0
                                    ? nodePathMembers[nodePathMembers.length - 1].id !=
                                    row.original.id
                                    : true,
                        })}
                        onClick={() => getSelectMember(row.original.id, row.original.createdTime, row.original.updateTime)}
                    >
                        {row.original.memberName}
                    </span>
                );
            },
            Footer: () => {
                return (
                    <span>
                        <b>{t("TOTAL")}: </b>
                    </span>
                );
            },
        },
        {
            Header: "AMOUNT",
            accessor: "amount",
            Cell: ({ row }) => {
                return (
                    <>{numberWithCurrencyFormat(parseFloat(row.original.amount), 3)}</>
                );
            },
            Footer: ({ page }) => {
                const creditSum = page.reduce((sum, currentValue) => {
                    if (currentValue.original.amount){
                        return sum + parseFloat(currentValue.original.amount);
                    }
                    else {
                        return sum;
                    }
                }, 0);
                return <span><b>{numberWithCurrencyFormat(parseFloat(creditSum), 3)}</b></span>
            }
        },
        {
            Header: "STATUS",
            accessor: "status",
            Cell: ({ row }) => {
                return row.original.status ? (
                    <span className="badge badge-green">{t("SETTLE")}</span>
                ) : (
                    <span className="badge badge-danger">{t("CREDIT")}</span>
                );
            },
        },
        {
            Header: "CREDIT_LIMIT",
            accessor: "creditLimit",
            Cell: ({ row }) => {
                return row.original.creditLimit === 0 ? (
                    <>{ t("-") }</>
                ) : (
                        <>{ row.original.creditLimit }</>
                );
            },
        },
        {
            Header: "REMARK",
            accessor: "remark",
        },
        {
            Header: "OPERATED_BY",
            accessor: "operatedBy",
        },
        {
            Header: "UPDATED_TIME",
            accessor: "updateTime",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <>{moment(row.original.updateTime).format("DD-MM-YYYY HH:mm:ss")}</>
                );
            },
        },
        {
            Header: "CREATED_TIME",
            accessor: "createdTime",
            minWidth: 100,
            Cell: ({ row }) => {
                return (
                    <>{moment(row.original.createdTime).format("DD-MM-YYYY HH:mm:ss")}</>
                );
            },
        },
    ];



    /// <summary>
    /// Author : -
    /// </summary>
    function getOverallInfo(info) {
        setOverallInfo(info);
    }

    /// <summary>
    /// Author : -
    /// </summary>
    async function getSelectMember(id, createdTime, updateTime) {
         {
            _history.push({
                pathname: "memberCreditReportLog",
                search: "?memberId=" +
                    id +
                    "&startDate=" +
                    createdTime +
                    "&endDate=" +
                    updateTime +
                    "&option='fromReport'"
            });
        }
    }


    return (
        <div>
            <h1 className="page-header">{t("MEMBER_CREDIT_REPORT")}</h1>
            <Panel>
                <PanelBody>
                    <div className="row">
                        <div className="col-lg-12">

                            <ReactTable
                                fetchUrl={apiUrl}
                                columns={_tableColumns}
                                getOverallInfo={getOverallInfo}
                                renderFooter={true}
                            />
                        </div>
                    </div>
                </PanelBody>
            </Panel>
        </div>
    );
};

export default MemberCreditReport;
