import React, { useState, useEffect } from "react";
import ReactTable from "../../components/constantComponent/reactTable/ReactTable";
import { Panel, PanelBody } from "./../../components/panel/panel.jsx";
import { ApiKey, ApiUrl, LanguageKey } from "../../util/Constant";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { fetch } from "whatwg-fetch";
import { setBusy, setIdle, showMessage, setSaveAdminLog } from "../../redux/AppAction";
import { useHistory } from "react-router-dom";

/// <summary>
/// Author : -
/// </summary>

const BankSummaryStatement = (props) => {
  const [statementDatas, setStatementDatas] = useState([]);
  const { t } = useTranslation(LanguageKey._PRIMARY);
  const _dispatch = useDispatch();
  let _history = useHistory();

  /// <summary>
  /// Author : -
  /// </summary>
  useEffect(() => {
    init();
  }, []);

  /// <summary>
  /// Author : -
  /// </summary>
  const init = async () => {
    _dispatch(setBusy());
    await fetch(
      ApiUrl._API_GET_BANK_SUMMARY_STATEMENT +
        "?bankAccountId=" +
        props.data.bankAccountId,
      {
        method: ApiKey._API_GET,
        headers: {
          "Content-Type": ApiKey._API_FORM_URLENCODED,
          "X-Requested-With": ApiKey._API_XML_HTTP_REQUEST_KEY,
        },
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
          setStatementDatas(responseJson[ApiKey._API_DATA_KEY]);
          _dispatch(setSaveAdminLog("VIEW_BANK_SUMMARY_REPORT_ACCOUNT_STATEMENT", _history.location.pathname, responseJson[ApiKey._API_DATA_KEY]))
        } else {
          _dispatch(
            showMessage(
              responseJson[ApiKey._API_SUCCESS_KEY],
              t(responseJson[ApiKey._API_MESSAGE_KEY])
            )
          );
        }
        _dispatch(setIdle());
      });
  };

  let _tableColumns = [
    {
      Header: "ID",
      accessor: "id",
      Cell: ({ row }) => {
        return <>{row.index + 1}</>;
      },
    },
    {
      Header: t("BANK"),
      accessor: "bankName",
    },
    {
      Header: t("ACCOUNT"),
      accessor: "bankAccountNumber",
    },
    {
      Header: t("DATETIME"),
      accessor: "date",
      Cell: ({ row }) => {
        return <>{moment(row.original.date).format("YYYY-MM-DD HH:mm:ss")}</>;
      },
    },
    {
      Header: t("CREDIT"),
      accessor: "credit",
      Cell: ({ row }) => {
        if (row.original.credit > 0) {
          return <>{row.original.credit.toFixed(2)}</>;
        } else {
          return <span></span>;
        }
      },
    },
    {
      Header: t("DEBIT"),
      accessor: "debit",
      Cell: ({ row }) => {
        if (row.original.debit > 0) {
          return (
            <span style={{ color: "red" }}>
              -{row.original.debit.toFixed(2)}
            </span>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      Header: t("REMARK"),
      accessor: "remark",
    },
    {
      Header: t("ADMIN"),
      accessor: "admin",
    },
  ];

  return (
    <div>
      <Panel>
        <PanelBody>
          <div className="row">
            <div className="col-lg-12">
              <ReactTable data={statementDatas} columns={_tableColumns} />
            </div>
          </div>
        </PanelBody>
      </Panel>
    </div>
  );
};

export default BankSummaryStatement;
